<template>
    <div>
        <main class="level-container">
            <div class="main-title">
                <h2 style="margin-bottom: 15px;">{{ displayEventName }}</h2>
            </div>
            <div id="loadingMessage">
                🎥 Unable to access video stream (please make sure you have a webcam
                enabled)
            </div>
            <canvas id="canvas" hidden></canvas>
            <div id="output" hidden>
                <div id="outputMessage">No QR code detected.</div>
                <div hidden>
                    <b></b> <span id="outputData" ref="outputData"></span>
                </div>
            </div>

            <!-- 掃描完的結果 -->
            <ScanResult :events="events" :isDisplayResult="isDisplayResult" :member="member" :hasConfirmBtn="hasConfirmBtn"
                @scan-finish="resetAll()" />
        </main>
    </div>
</template>

<script>
import jsQR from "jsqr";
import ScanResult from '../components/ScanResult.vue'
import {
    hasConfirmBtn,
    activitiesInfo
} from "../data";

export default {
    name: "cameraScan",
    components: {
        ScanResult
    },
    data() {
        return {
            initailized: false,
            // 掃描完的結果
            events: Object.values(activitiesInfo).map(activity => activity.event).flat(),
            isDisplayResult: false,
            member: {
                id: "",
                // name: "",
                // phone: "",
                reset: function () {
                    this.id = "";
                    this.name = "";
                    this.phone = "";
                }
            },
        };
    },
    created() {
        // this.canvas = this.canvasElement.getContext("2d");
        this.stopScan()
    },
    mounted() {
        this.startCameraScan()
    },
    computed: {
        // 不會變化，用 computed 緩存
        currentEventId() {
            return this.$route.params.eventId;
        },
        displayEventName() {
            if (this.events) {
                const foundEvent = this.events.find((event) => event.code === this.currentEventId);
                return foundEvent ? foundEvent.label : "";
            } else {
                return "";
            }
        },
        hasConfirmBtn() {
            return hasConfirmBtn.cameraScan;
        }
    },
    methods: {
        startCameraScan() {
            var video = document.createElement("video");
            var canvasElement = document.getElementById("canvas");
            var canvas = canvasElement.getContext("2d");
            var loadingMessage = document.getElementById("loadingMessage");
            var outputContainer = document.getElementById("output");
            var outputMessage = document.getElementById("outputMessage");
            var outputData = document.getElementById("outputData");

            // var video = this.video;
            // var canvasElement = this.canvasElement;
            // var canvas = this.canvas;
            // var loadingMessage = this.loadingMessage;
            // var outputContainer = this.outputContainer;
            // var outputMessage = this.outputMessage;
            // var outputData = this.outputData;

            function drawLine(begin, end, color) {
                canvas.beginPath();
                canvas.moveTo(begin.x, begin.y);
                canvas.lineTo(end.x, end.y);
                canvas.lineWidth = 4;
                canvas.strokeStyle = color;
                canvas.stroke();
            }

            // Use facingMode: environment to attemt to get the front camera on phones
            navigator.mediaDevices
                .getUserMedia({ video: { facingMode: "environment" } })
                .then(function (stream) {
                    video.srcObject = stream;
                    video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
                    video.play();
                    requestAnimationFrame(tick);
                });

            var that = this;
            function tick() {
                loadingMessage.innerText = "⌛ Loading video...";
                if (video.readyState === video.HAVE_ENOUGH_DATA) {
                    loadingMessage.hidden = true;
                    canvasElement.hidden = false;
                    outputContainer.hidden = false;

                    // canvasElement.height = video.videoHeight;
                    // canvasElement.width = video.videoWidth;
                    canvasElement.height = video.videoHeight * 0.45;
                    canvasElement.width = video.videoWidth * 0.45;

                    canvas.drawImage(
                        video,
                        0,
                        0,
                        canvasElement.width,
                        canvasElement.height
                    );
                    var imageData = canvas.getImageData(
                        0,
                        0,
                        canvasElement.width,
                        canvasElement.height
                    );
                    var code = jsQR(
                        imageData.data,
                        imageData.width,
                        imageData.height,
                        {
                            inversionAttempts: "dontInvert"
                        }
                    );
                    if (code) {
                        drawLine(
                            code.location.topLeftCorner,
                            code.location.topRightCorner,
                            "#FF3B58"
                        );
                        drawLine(
                            code.location.topRightCorner,
                            code.location.bottomRightCorner,
                            "#FF3B58"
                        );
                        drawLine(
                            code.location.bottomRightCorner,
                            code.location.bottomLeftCorner,
                            "#FF3B58"
                        );
                        drawLine(
                            code.location.bottomLeftCorner,
                            code.location.topLeftCorner,
                            "#FF3B58"
                        );
                        outputMessage.hidden = true;
                        outputData.parentElement.hidden = false;
                        if (that.scanAction(code)) {
                            outputData.innerText = "";
                            return;
                        } else {
                            outputData.innerText = "會員ID錯誤！！";
                        }
                    } else {
                        // outputMessage.hidden = false;
                        // outputData.parentElement.hidden = true;
                    }
                }
                requestAnimationFrame(tick);
            }
        },
        scanAction(code) {
            let outputData = this.$refs.outputData;
            outputData.innerHTML = code;
            // outputData.innerHTML = code.data;
            // this.$emit("scan-result", code);
            if (this.checkScanDataAvaliable(code)) {
                this.getMemberData(code);
                this.displayResult()
                return true
            } else {
                this.hideResult();
                return false;
            }
        },
        checkScanDataAvaliable(event) {
            if (event && event.data) { // JSON format (OLD)
                // var lineIdPatt = new RegExp(/U[0-9a-f]{32}$/);
                // return lineIdPatt.test(event.data);
                try {
                    JSON.parse(event.data);
                    return true;
                } catch (err) {
                    return false;
                }
            } else { // string format
                console.log('string format');
                return true;
            }
            // return false;
        },

        // 掃描完的結果
        getMemberData(event) {
            Object.assign(this.member, { // 現在不用JSON
                id: event.data
                // name: parseData.name,
                // phone: parseData.phone
            });
            // let parseData = JSON.parse(event.data);
            // Object.assign(this.member, {
            //     id: parseData.id,
            //     name: parseData.name,
            //     phone: parseData.phone
            // });
        },
        displayResult() {
            this.isDisplayResult = true;
        },
        hideResult() {
            this.isDisplayResult = false;
        },
        resetAll() {
            this.hideResult()
            this.member.reset()
            this.startCameraScan()
        },

        // 定義一個方法，用於向 Android 傳遞數據
        // Android & stopScan 都是自定義的 , 可以跟 Android 工程師依照功能需求一起訂一個名字
        // 傳遞的參數可以0～N個 , 可以跟 Android 工程師一起討論需要哪些參數或如何傳遞
        stopScan() {
            if (window.Android && window.Android.stopScan) {
                console.log("/camera-scan/:eventId 頁面 Send data to Android: stopScan");

                window.Android.stopScan();
            }
        }
    }
};
</script>