import ScanStamp from "../views/ScanStamp.vue";
import BarcodeScan from "../components/BarcodeScan.vue";
import CameraScan from "../components/CameraScan.vue";
import { activitiesInfo } from "../data/index";

export const generateRouteConfig=(name, path)=> {
  return [
    {
      path: `/${path}`,
      name: name,
      component: ScanStamp
    },
    {
      path: `/camera-scan/${path}/:eventId`,
      name: `${name}-cameraScan`,
      component: CameraScan
    },
    {
      path: `/barcode-scan/${path}/:eventId`,
      name: `${name}-barcodeScan`,
      component: BarcodeScan
    }
  ];
}

export const routesConfig = Object.entries(activitiesInfo).map(([key, activity]) => {
    const { route } = activity;
    return generateRouteConfig(key, route);
}).flat();