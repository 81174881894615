import Vue from "vue";
import Router from "vue-router";
// import { hasScriptById, injectScript, handleAuth } from "./utils/auth";
import { routesConfig } from "./utils/helpers";

Vue.use(Router);

const appRouter = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routesConfig
});

/* eslint-disable */
// console.log("VUE_APP_SDK_SRC", process.env.VUE_APP_SDK_SRC);
// appRouter.beforeEach((to, from, next) => {
//   console.log("to", to);
//   console.log("from", from);
//   console.log("next", next);
//   if (to?.meta?.auth) {
//     console.log("要做登入");
//     if (!hasScriptById("SSO_SDK")) {
//       console.log("if");
//       injectScript({
//         id: "SSO_SDK",
//         src: process.env.VUE_APP_SDK_SRC,
//         callbackParams: { to, from, next, path: to.path },
//         // [SSO 登入, 處理路由導向]
//         callback: handleAuth,
//       });
//     } else {
//       console.log("else");
//       // [SSO 登入, 處理路由導向]
//       handleAuth({ to, from, next, path: to.path });
//     }
//   } else {
//     console.log("不要做登入");
//     next();
//   }
// });

/* eslint-enable */

export default appRouter;
