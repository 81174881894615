<!-- 之後要複用 methods: stopScan -->
<template>
    <div v-if="initailized">
        <main class="level-container">
            <div class="main-title">
                <h2 style="margin-bottom: 15px;">{{ displayEventName }}</h2>
            </div>
            <div>
                使用條碼掃描器前，請確保鍵盤設定為英文模式，並將滑鼠指標聚焦在輸入框內。
            </div>
            <input v-model="inputValue" ref="inputRef" @keyup.enter="handleInputkeyup" pattern="^[A-Za-z0-9]+$"
                title="只允許英文字母輸入" style="margin: 20px;" />
            <br />

            <!-- 掃描完的結果 -->
            <ScanResult :events="events" :isDisplayResult="isDisplayResult" :member="member" :hasConfirmBtn="hasConfirmBtn"
                @scan-finish="resetAll()" />
        </main>
    </div>
</template>

<script>
import ScanResult from '../components/ScanResult.vue'
import {
    hasConfirmBtn,
    activitiesInfo
} from "../data";

export default {
    name: "barcodeScan",
    components: {
        ScanResult
    },
    data() {
        return {
            initailized: false,
            // barcode scanner
            inputValue: "",
            // 掃描完的結果
            events: Object.values(activitiesInfo).map(activity => activity.event).flat(),
            isDisplayResult: false,
            member: {
                id: "",
                // name: "",
                // phone: "",
                reset: function () {
                    this.id = "";
                    this.name = "";
                    this.phone = "";
                }
            },
        };
    },
    mounted() {
        console.log("currentEventId", this.currentEventId)
        this.initailized = true;

        // 頁面加載後，自動聚焦到 input
        this.$nextTick(() => {
            this.$refs.inputRef?.focus();
        });

        // 監聽全螢幕的點擊事件，當點擊事件發生時，將焦點重新設定回 inputRef
        document.addEventListener("click", this.handleClickRefocusInput);

        console.log("displayEventName", this.displayEventName)
        console.log("events", this.events)
    },
    computed: {
        // 不會變化，用 computed 緩存
        currentEventId() {
            return this.$route.params.eventId;
        },
        displayEventName() {
            if (this.events) {
                const foundEvent = this.events.find((event) => event.code === this.currentEventId);
                return foundEvent ? foundEvent.label : "";
            } else {
                return "";
            }
        },
        hasConfirmBtn() {
            return hasConfirmBtn.barcodeScan;
        }
    },
    beforeDestroy() {
        // 在組件銷毀前，移除點擊事件的監聽器，避免出現記憶體洩漏
        document.removeEventListener("click", this.handleClickRefocusInput);

        // 通知 Android，要停止掃描
        this.stopScan()
    },
    methods: {
        // 檢查輸入是否為英文數字
        isEnglishAlphanumeric(text) {
            const pattern = /^[A-Za-z0-9]+$/;
            return pattern.test(text);
        },
        handleInputkeyup() {
            if (this.inputValue !== '') {
                if (!this.isEnglishAlphanumeric(this.inputValue)) {
                    alert("使用條碼掃描器，請確認輸入內容僅包含英文或數字")
                } else {
                    // 在 input enter 時觸發，可以在這裡處理掃描器掃描完成後的值
                    this.getMemberData({ data: this.inputValue });
                    this.displayResult()
                }
            } else {
                this.hideResult();
            }
        },
        handleClickRefocusInput(event) {
            // 點擊事件發生時，檢查點擊的目標是否在 input 範圍內，如果不是則將焦點重新設定回 inputRef
            if (!this.$refs.inputRef?.contains(event.target)) {
                this.$refs.inputRef.focus();
            }
        },

        // 定義一個方法，用於向 Android 傳遞數據
        // Android & startScan 都是自定義的 , 可以跟 Android 工程師依照功能需求一起訂一個名字
        // 傳遞的參數可以0～N個 , 可以跟 Android 工程師一起討論需要哪些參數或如何傳遞
        startScan() {
            if (window.Android && window.Android.startScan) {
                console.log("Send data to Android: startScan");

                window.Android.startScan();
            }
        },
        stopScan() {
            if (window.Android && window.Android.stopScan) {
                console.log("/barcode-scan/:eventId頁面 Send data to Android: stopScan");

                window.Android.stopScan();
            }
        },

        // 定義供 Android 回傳數據的方法
        // Android 或 iOS 可以用這樣的方法把東西從 App 丟上來, 但只能接收一個變數, 方法的名稱可以跟 Android / iOS 工程師一起討論
        receiveDataFromAndroid(result) {
            console.log("Received data from Android:", result);

            // 可以在這裡處理掃描器掃描完成後的值
            if (result !== '') {
                this.getMemberData({ data: result });
                this.displayResult()
            } else {
                this.hideResult();
            }
        },

        // 掃描完的結果
        getMemberData(event) {
            Object.assign(this.member, { // 現在不用JSON
                id: event.data
                // name: parseData.name,
                // phone: parseData.phone
            });
            // let parseData = JSON.parse(event.data);
            // Object.assign(this.member, {
            //     id: parseData.id,
            //     name: parseData.name,
            //     phone: parseData.phone
            // });
        },
        displayResult() {
            this.isDisplayResult = true;
        },
        hideResult() {
            this.isDisplayResult = false;
        },
        resetAll() {
            this.hideResult()
            this.member.reset()
            this.inputValue = ""
        },
    },
    created() {
        window.receiveDataFromAndroid = this.receiveDataFromAndroid;

        // 通知 Android，要開始掃描，讓掃描機一直維持掃描
        this.startScan()
    },
};
</script>


<style scoped>
input {
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
}

/* 設定有效輸入(英文數字)時的顯示效果 */
input:valid {
    border-color: green;
    /* 合法輸入時，邊框變綠色 */
}

/* 設定無效輸入時的顯示效果 */
input:invalid {
    border-color: red;
}
</style>