<template>
    <div v-if="initailized">
        <main class="level-container">
            <div class="main-title">
                <!-- <img src="images/main-title.png" width="100%" title="東岸舖食節"> -->
                <h2>{{ title }}</h2>
            </div>
            <!-- <p v-if="userName" style="margin: 10px;">登入者：{{ userName }}</p> -->
            <!-- <div style="background-color:azure; width: 80px; height: 40px; margin: auto; display: flex; justify-content: center; align-items: center; cursor: pointer; border-radius: 4px;"
                @click="logout()">
                登出</div> -->
            <section class="content">
                <ul>
                    <li v-for="event in events" :key="event.code">
                        <router-link :to="`/barcode-scan/${path}/${event.code}`">
                            <input class="btn" type="button" :value="'掃碼機：' + event.label" id="level-input-1" />
                        </router-link>
                        <router-link :to="`/camera-scan/${path}/${event.code}`">
                            <input class="btn" type="button" :value="'攝像頭：' + event.label" id="level-input-2" />
                        </router-link>
                        <p>{{ event.description }}</p>
                    </li>
                </ul>
            </section>
        </main>
    </div>
</template>

<script>
import {
    routers,
    titles,
    activitiesInfo
} from "../data";

export default {
    name: "stamps",
    data() {
        return {
            initailized: false,
            path: this.$route.path.slice(1).trim(),
        };
    },
    mounted() {
        this.initailized = true;
    },
    created() {
        this.stopScan()
    },
    methods: {
        // 定義一個方法，用於向 Android 傳遞數據
        // Android & stopScan 都是自定義的 , 可以跟 Android 工程師依照功能需求一起訂一個名字
        // 傳遞的參數可以0～N個 , 可以跟 Android 工程師一起討論需要哪些參數或如何傳遞
        stopScan() {
            if (window.Android && window.Android.stopScan) {
                console.log("/ 頁面 Send data to Android: stopScan");

                window.Android.stopScan();
            }
        },
        // logout() {
        //     console.log("VUE_APP_REDIRECT_URI", process.env.VUE_APP_REDIRECT_URI)
        //     /* eslint-disable */
        //     console.log("登出logout", process.env.VUE_APP_REDIRECT_URI)
        //     qcsso.logout({
        //         logoutRedirectUri: process.env.VUE_APP_REDIRECT_URI
        //     }).then(() => {
        //         console.log("登出完成")
        //     }).catch((err) => console.log("err", err))
        //     /* eslint-disable */
        // }
    },
    computed: {
        // userName() {
        //     console.log("computedcomputed", localStorage.getItem('userName'))
        //     return localStorage.getItem('ttpush-userName');
        // },
        title() {
            const titlesMapping = Object.keys(routers).reduce((acc, key) => {
                acc[routers[key]] = titles[key];
                return acc;
            }, {});
            return titlesMapping[this.path] || null;
        },
        events() {
            const eventMapping = Object.keys(routers).reduce((acc, key) => {
                acc[routers[key]] = activitiesInfo[key].event;
                return acc;
            }, {});
            return eventMapping[this.path] || null;
        }
    },
};
</script>

<style>
@charset "UTF-8";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
    background-color: #f6f6f6;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.clearfix:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

* html .clearfix {
    height: 1%;
}

*:first-child+html .clearfix {
    min-height: 1%;
}

a {
    text-decoration: none;
    outline: none;
    -webkit-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

a:hover {
    text-decoration: none;
}

*:link,
*:visited,
*:hover,
*:active,
*:focus,
* {
    border: none;
    outline: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: "Noto Sans TC", Arial, "微軟正黑體", "新細明體", sans-serif;
    font-weight: normal;
    color: #333;
}

p {
    font-size: 0.9375em;
}

a {
    cursor: pointer;
    text-decoration: none;
    outline: none;
    -webkit-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

a:hover {
    text-decoration: none;
}

body {
    font-size: 100%;
    border-color: #9e9e9e;
}

.level-container {
    padding: 20px 10px;
    width: 100%;
    min-height: calc(100vh);
    background-color: #eddecb;
    overflow: hidden;
}

.level-container .main-title {
    text-align: center;
    padding: 20px 0 10px;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
}

.level-container .content {
    margin: 0 auto;
    max-width: 320px;
}

.level-container .content ul li {
    padding: 15px 0;
    border-bottom: 2px dashed #dfc2a4;
}

.level-container .content ul li p {
    font-size: 13px;
    padding-top: 8px;
    line-height: 16px;
}

.level-container .content ul li:last-child {
    border: none;
}

.level-container .content input {
    cursor: pointer;
    font-size: 18px;
    padding: 10px;
    width: 100%;
    color: #fff;
    border-radius: 4px;
    line-height: 24px;
}

#level-input-1 {
    background-color: #cd8c49;
    background: url(../assets/bg_btn.png) center center no-repeat;
    background-size: cover;
    margin-bottom: 15px;
}

#level-input-2 {
    background-color: #13bdac97;
}

.level-container .user-content {
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    margin: 0 auto;
    max-width: 320px;
    position: relative;
}

.level-container .user-content h3 {
    text-align: center;
    font-size: 22px;
    padding: 15px 0;
    font-weight: bold;
}

.level-container .user-content ul li {
    padding: 5px 0 10px 0;
    border-bottom: 2px dashed #e7dacc;
}

.level-container .user-content ul li p {
    text-align: center;
    font-size: 13px;
    padding: 5px 0;
    line-height: 16px;
    color: #555;
}

.level-container .user-content ul li p.show {
    font-size: 24px;
    color: #a95d0f;
}

.level-container .user-content ul li:last-child {
    border: none;
}

.level-container .user-content input {
    cursor: pointer;
    font-size: 18px;
    padding: 10px;
    width: 100%;
    color: #fff;
    background-color: #ef4d86;
    border-radius: 4px;
    line-height: 24px;
}

.level-container .user-content p.done {
    top: 157px;
    right: -20px;
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    color: #ef4d86;
    border: 3px solid #ef4d86;
    font-size: 28px;
    text-align: center;
    font-weight: bold;
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
}

.level-container .user-content p.error {
    width: 100%;
    color: #ef4d86;
    border-radius: 4px;
    font-size: 28px;
    text-align: center;
    line-height: 30px;
    padding: 10px;
    font-weight: bold;
}

@media only screen and (min-width: 600px) {
    .level-container .content {
        max-width: 680px;
    }

    .level-container .content input {
        width: 45%;
    }

    #level-input-1 {
        margin-right: 30px;
    }

}

/*# sourceMappingURL=style.css.map */
</style>
